import { useState, useCallback, useEffect } from "react";
import {
    Grid, GridColumn, GridItemChangeEvent, GridCellProps, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent,
    getSelectedState, GridPageChangeEvent, GridRowProps, GridFooterCellProps, GridSortChangeEvent, GridExpandChangeEvent,
    GridColumnMenuCheckboxFilter, GridColumnMenuProps
    , GridHeaderCellProps, GridToolbar, GridColumnMenuFilter, GridFilterChangeEvent, GridPagerSettings, GridNoRecords, GridDetailRowProps
} from "@progress/kendo-react-grid";
import { SortDescriptor, CompositeFilterDescriptor, orderBy, filterBy, isCompositeFilterDescriptor } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { CellRender, RowRender } from "./gridrenderers";
import { IGridProps } from "./IGridProps"
import * as React from "react";
import { IPageState } from "./IPageState"
import { GridSelectionType } from "./SelectionType";
import { ChoiceGroup, CommandBarButton, FontIcon, IChoiceGroupOption, IIconProps, PrimaryButton, Stack } from "@fluentui/react";
import { ActionButtonStyles, _Styles } from "../../../content/styles/Page.styles";
import { IKendoGridColumnProps } from "./IKendoGridColumnProps";
import { ApplicationConstants } from "../../../models/ApplicationConstants";
import { MenuItems } from "../verticalmenu/menuItems";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { FilterMenu, FilterMenuCheckboxFilter, IsFilterActive } from "./gridfilter";
import { CssClassConstants } from "../../../content/CssClassConstants";
import { UIControlsText } from "../../../content/UIControlsText";
import { getLoggedinUserRoleDetails, getUserRole, getUserSession, getValuesForCheckboxList, intializeSessionConstants, isNullOrEmpty, readGridConfig, updateDefaultPageSizesInSession, gridAccessibilityFix } from "../common/util";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import moment from "moment";
import { CustomKendoGridCellConstants } from "../customkendogridcellcontants";

import { MultiSelectDropDown } from "../multiSelectDropDown/multiSelectDropDown";
import { ActionMenu } from "../action-menu/action-menu";
import { manufactureConfirmatonLetterVisible, enableActionMenu } from "../action-menu/actionMenuUtil";
import { IActionMenuProps } from "../action-menu/IActionMenuProps";
import NextGenKendoChildGrid from "./nextgenkendochildgrid";
import { IGridChildProps } from "./IGridChildProps";
import { DropdownFilterCell } from "./dropDownFilterCell";
import { IActionButtonConfig } from "../action-menu/IActionButtonConfig";
import { IDashboardDataRequest } from "../../../models/IDashboardDataRequest";
import { nextGenService } from "../../../services/NextGenService";
import { GRID_COL_INDEX_ATTRIBUTE, } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import SkuComponent from "../skucustomkendogriddetail";
import { DealType } from "../../../models/DealType";
import { GridType } from "../../../models/GridType";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl"; 
import { messages } from "./gridLocaleMessages";
import { useHistory } from "react-router-dom";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { getUserMainRole } from "../../../components/shared/common/util";
import { UserRole } from "../../../models/UserRole";
import { useToAddRoleToGridColumnFilterButton, useToHandleOpenColumnMenuOnKeyboardEnter} from "../../../hooks/accessiblity-hooks";
import { useToRemoveInvalidChildRole, useToFixGridAriaRequiredChildren } from "../../../hooks/accessiblity-hooks";

/**
 * DashboardGrid Component.
 * @function component
 */

const DashboardGrid: React.FunctionComponent<IGridProps> = (props: IGridProps) => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const idGetter = getter(props.kendoGridProps.dataItemKey);
    const detailIdGetter = getter(props.kendoGridProps.dataItemKey === "undefined" ? "id" : props.kendoGridProps.dataItemKey);
    const [selectedCheckState, setSelectedCheckState] = useState<{ [id: string]: boolean | number[]; }>({});
    const [detailSelectedCheckState, setDetailSelectedCheckState] = useState<{ [id: string]: boolean | number[]; }>({});
    const initialSort: Array<SortDescriptor> | undefined = [{ field: props.kendoGridProps.initialSortField, dir: "asc" }];
    const [sort, setSort] = useState(initialSort);
    const [editField, setEditField] = useState<string | undefined>(undefined);
    const [gridData, setGridData] = useState<any[]>([]);
    const [gridCount, setGridCount] = useState<number>(props.kendoGridProps.totalRecords);
    const initialPageState: any = { skip: 0, take: props.kendoGridProps.noOfRecords };
    const initialPageStateChildGrid: IPageState = { skip: 0, take: props.kendoGridProps.detailGrid?.noOfRecords };
    const [page, setPage] = useState<any>(initialPageState);
    const [childGridPage, setChildGridPage] = useState<IPageState>(initialPageStateChildGrid);
    var initialFilter: CompositeFilterDescriptor | undefined = { logic: "and", filters: [] }
    const [filter, setFilter] = useState(initialFilter);
    const [gridWidth, setGridWidth] = useState("auto");
    const [userRoles] = useState<string>(getUserRole());
    var date = moment().utcOffset('+05:30').format('YYYY-MM-DD');
    const [dataState, setDataState] = React.useState<any[]>([]);
    const [actionMenuConfig, setActionMenuConfig] = React.useState<IActionMenuProps>();
    const [toggleText, setToggleExpandCollapse] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll));
    const [columns, setColumns] = useState(props.kendoGridProps.columns)
    const [isDownloading, setIsDownloading] = useState(false);
    const [defaultGridState] = useState(readGridConfig(props.kendoGridProps.gridName));
    const ExportIcon: IIconProps = { iconName: CssClassConstants.DownloadIconName };
    const [excelData, setExcelData] = useState<any[]>([]);
    const [localeMessageForMoreRecords, setlocaleMessageForMoreRecords] = useState<string>(ApplicationConstants.EnLocaleForGrid);
    const [filterValue, setFilterValue] = useState<string>('');
    const history = useHistory();
    const [userMainRole] = useState<string>(getUserMainRole());

    //listening the props change and reset gridData state
    useEffect(() => {
        if (props.kendoGridProps.defaultMainGridSelectionstate) {
            setSelectedCheckState(props.kendoGridProps.defaultMainGridSelectionstate);
        }
        if (props.kendoGridProps.defaultDetailGridSelectionstate) {
            setDetailSelectedCheckState(props.kendoGridProps.defaultDetailGridSelectionstate);
        }
        SetGridWidth();
        setGridData(props.kendoGridProps.data);
        setExcelData(props.kendoGridProps.excelData)
        setGridCount(isNullOrEmpty(props.kendoGridProps.totalRecords) ? props.kendoGridProps.data?.length : props.kendoGridProps.totalRecords);
        window.addEventListener('resize', SetGridWidth);
        setToggleExpandCollapse(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll));
        setColumns(props.kendoGridProps.columns)
        var initialPageState: any
        if(props.kendoGridProps.isRefresh){
            initialPageState=  { skip: 0, take: props.kendoGridProps.noOfRecords };
        }else{
          initialPageState=  { skip: page.skip, take: props.kendoGridProps.noOfRecords };
        }
        if(props.kendoGridProps.isFilterAppliedOrRemoved===true){
            var initialFilter: CompositeFilterDescriptor | undefined = { logic: "and", filters: [] }
            setFilter(initialFilter)
        }
        setPage(initialPageState);
    }, [props.kendoGridProps.data, props.kendoGridProps.defaultMainGridSelectionstate, props.kendoGridProps.columns, props.kendoGridProps.noOfRecords, props.kendoGridProps.excelData])

    useEffect(() => {
        if (props.kendoGridProps.defaultDetailGridSelectionstate) {
            setDetailSelectedCheckState(props.kendoGridProps.defaultDetailGridSelectionstate);
        }

        intializeSessionConstants()
    }, [props.kendoGridProps.defaultDetailGridSelectionstate])

    // accessibility - see hook definition for more details 
    useToAddRoleToGridColumnFilterButton();

    //customize the cell to render of selected legal grid
    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, props: GridCellProps) => {
        if (props.rowType === "groupHeader") {
            const dataItem = props.dataItem;
            if (td && td.props && td.props.children) {
                if (dataItem.field === "caseStartDate" || dataItem.field === "expirationDate") {
                    let children = (
                        <span>
                            {moment(dataItem.value).format('MM/DD/YYYY')}
                        </span>
                    );
                    return React.cloneElement(td, td.props, children);
                }
            }
            return td;
        } else {
            return <CellRender originalProps={props} td={td} enterEdit={enterEditonSelectedData} editField={editField} />
        }
    };
    //customize the row to render of selected legal grid
    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => (
        <RowRender originalProps={props} tr={tr} exitEdit={exitEditonSelectedData} editField={editField} />
    );
    //Editable cell creation on selecte Legals - on typing editable cell update value from user
    const onItemChange = (e: GridItemChangeEvent) => {
        let field = e.field || "";
        let colProps = props.kendoGridProps.columns.filter((col: IKendoGridColumnProps) => {
            if (col.apiField === field)
                return col.editorType;
        })[0] as IKendoGridColumnProps;
        if (colProps) {
            if (colProps.editorType === ApplicationConstants.NumericEditorType && e.value < 0) {
                return;
            }
        }
        e.dataItem[field] = e.value;
        let newData = gridData.map((item: any) => {
            if (item.id === e.dataItem.id) {
                item[field] = e.value;
            }
            return item;
        });
        props.kendoGridProps.data = [...newData];
        setGridData(newData);
    };

    //--event for checkbox header selection in the search grid: when header checked all the child checkbox will be checked
    const onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            if (props.disableSelectAllCheckbox) {
                return;
            }
            const checkboxElement: any = event.syntheticEvent.target
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};
            event.dataItems.forEach((item: any) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedCheckState(newSelectedState);
            props.onRowSelectionCallBack?.(newSelectedState);
        }, []);
    //event to trigger on change of checkbox in the grid
    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            if (event.dataItem.editMode) {
                return;
            }
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedCheckState,
                dataItemKey: props.kendoGridProps.dataItemKey,
            });
            setSelectedCheckState(newSelectedState);
            //to enable check uncheck based on config, when multiple
            if (event.syntheticEvent !== undefined) {
                const checkboxElement: any = event.syntheticEvent.target
                const checked = checkboxElement.checked;
                if (props.kendoGridProps.selectionType == GridSelectionType.Single) {
                    event.dataItems.forEach((item: { id: any; }) => {
                        if (event.dataItem.id === item?.id) {
                            newSelectedState[idGetter(item)] = checked
                        } else {
                            newSelectedState[idGetter(item)] = false;
                        }
                    });
                    props.onPrimarySelectionCallBack?.(event.dataItem, checked);
                }
            }
            props.onRowSelectionCallBack?.(newSelectedState);
        },
        [selectedCheckState]
    )
    //--- Start of  hierarchal grid ---//
    const expandChange = (event: GridExpandChangeEvent) => {
        //alert(event.value)
        event.dataItem.expanded = event.value;
        //let categoryID = event.dataItem.CategoryID;
        setGridData([...gridData]);

        if (!event.value || event.dataItem.details) {
            return;
        }
        //populate data here on expand
        let data: any = gridData.slice();
        let index = data.findIndex((row: any) => row[props.kendoGridProps.groupByColumn] === event.dataItem[props.kendoGridProps.groupByColumn]);
        //data[index].details ="";
        data[index].details = props.kendoGridProps.masterData?.filter((item: any) => item[props.kendoGridProps.groupByColumn] === event.dataItem[props.kendoGridProps.groupByColumn]);//sub grid value goes here
        setGridData(data);
    };

    const DetailComponent = useCallback(
        (childprops: GridDetailRowProps) => {
            var childData = childprops.dataItem.details ? childprops.dataItem.details : [];
            let childGridProps: IGridChildProps = {
                parent: props,
                childGridData: childData,
                defaultChildGridSelectionstate: detailSelectedCheckState
            }
            return (
                <React.Fragment>
                    <NextGenKendoChildGrid {...{ ...childGridProps }} />
                </React.Fragment>
            );
        }, [detailSelectedCheckState]
    );
    //--- End of  hierarchal grid ---//

    //--Start of Inline Edit Grid Functionality --//
    //on click of row enable editing
    const enterEditonSelectedData = (dataItem: any, field: string) => {
        const newData = gridData.map((item: any) => ({
            ...item,
            inEdit: item.id === dataItem.id ? field : undefined,
        }));
        setGridData(newData);
        setEditField(field);
    };
    const exitEditonSelectedData = () => {
        let editedItem = gridData.filter((item: any) => (item.inEdit != undefined));

        if (props.kendoGridProps.editField) {
            const newData = gridData.map((item: any) => ({ ...item, inEdit: undefined }));
            setGridData(newData);
            setEditField(undefined);
        }
        props.onEditCallBack?.(editedItem[0]);
    };
    //--End of Inline Edit Grid Functionality --//

    //--Start of Delete Row in Grid Functionality --//
    //--End of Inline Edit Grid Functionality --//

    //--Start of Delete Row in Grid Functionality --//
    const gridDeleteCell = (props: GridCellProps) => (<DeleteCell {...props} remove={removeSelectedRow} />);
    //method to render delete icon in grid component
    const DeleteCell = (props: { editField?: any; remove?: any; dataItem?: any }) => {
        const { dataItem } = props;
        return dataItem.editMode ? (<td className="k-command-cell"></td>) : (
            <td >
                <FontIcon aria-label={CssClassConstants.DeleteIconName} iconName={CssClassConstants.DeleteIconName} className={_Styles.DeleteRow} onClick={() =>
                    props.remove(dataItem)
                }>
                </FontIcon>
            </td>
        );
    };
    //callback method for removing selected row from grid on delete button click
    const removeSelectedRow = (deletedRowData: any) => {
        if (!deletedRowData.editMode) {
            const newList = gridData.filter((item) => item.id !== deletedRowData.id);
            props.kendoGridProps.data = [...newList];
            setGridData([...newList]);
            if (props.onDeleteCallBack) {
                props.onDeleteCallBack(deletedRowData, newList);
            }
        }
    };
    //--End of Delete Row in Grid Functionality --//
    // --Start of Download Row in Grid Functionality--//
    const gridDownloadCell = (props: GridCellProps) => (<DownloadCell {...props} />);
    const DownloadCell = (props: { editField?: any; download?: any; dataItem?: any }) => {
        const { dataItem } = props;
        var gridContent = "data:application/pdf;base64," + dataItem.content
        return dataItem.editMode ? (<td className="k-command-cell" role="gridcell"></td>) : (
            <td >
                <a download={dataItem.name} href={gridContent}>
                    <FontIcon aria-label={CssClassConstants.DownloadIconName}
                        iconName={CssClassConstants.DownloadIconName}
                        className={_Styles.DeleteRow} ></FontIcon>
                </a>

            </td>
        );
    };

    // --End of Download Row in Grid Functionality--//

    //to calculate total of column in footer cell
    const footerCellwithTotal = (footerprops: GridFooterCellProps) => {
        const field = footerprops.field || "";
        var findItem = props.kendoGridProps.columns.find(x => x.apiField === field);
        var showFooter = false;
        if (findItem !== undefined) {
            showFooter = findItem.showFooter;
        }
        if (showFooter) {
            const total = gridData?.reduce((prev: number, current: { [x: string]: any }) => {
                return current[field] ? prev + Number(current[field]) : prev
            }, 0);
            return (<td colSpan={footerprops.colSpan} style={footerprops.style}> {findItem?.footerTitle} {total} </td>);
        }
        return (<td></td>);
    };
    const addNew = () => {
        const newDataItem = {
            inEdit: true,
            Discontinued: false
        };
        props.kendoGridProps.data = [newDataItem, ...gridData]
        setGridData([newDataItem, ...gridData]);
    };
    const MandatoryHeaderCell = (props: GridHeaderCellProps) =>
    <a>
        <span className="k-link">
            {props.title}<span className={"gridmandatoryField"}>*</span>
        </span>
    </a>
    const HeaderCell = (props: GridHeaderCellProps) =>
    <a>
        <span className="k-link" title={props.title}>
            {props.title}
        </span>
    </a>
    const allowView = (config: any) => {
        return (config?.allowColumnView?.length == 0 ? true : config?.allowColumnView?.some((r: any) => userRoles.includes(r)) ? true : false)
            && (config.isVisible == null || config.isVisible ? true : false);
    }
    const VerticalMenuCell = (menuprops: { dataItem?: any; }) => {
        const { dataItem } = menuprops;
        return <td >
            <MenuItems dashBoardConfig={props.kendoGridProps} dashBoardData={menuprops}
                selectedData={dataItem}  ></MenuItems>
        </td >
    };
    const gridErrorCell = (props: GridCellProps) => (<ErrorCell {...props} />);

    const ErrorCell = (props: { editField?: any; remove?: any; dataItem?: any }) => {
        const { dataItem } = props;
        
        return dataItem.editMode ? (<td className="k-command-cell" role="gridcell"></td>) : (
            <td >
                <p className="error-msg"> {dataItem.errors}</p>
                <p className="warning-msg"> {dataItem.warnings}</p>
            </td>
        );
    };

    //Fixing Accessibility for noRecords, sortIcons, GridPagerBtns & gridFootercell
    useEffect(() => {
        gridAccessibilityFix();
    });

    //Fixing Accessibility for sortIcons
    (function () {
        var sortIcons = document.querySelectorAll("span[aria-label='Sortable']");
        sortIcons.forEach((element) => {
            element.setAttribute("role", "button");
        });
    })();

    //Fixing Accessibility for GridPagerBtns & gridFootercell
    (function () {
        var pagerBtns = document.getElementsByClassName("k-icon");
        for (let i = 0; i < pagerBtns.length; i++) {
            pagerBtns[i].setAttribute("aria-hidden", "true");
        }
        document.querySelector("[role='columnfooter']")?.setAttribute('role', 'gridcell');
        var gridpager = document.getElementsByClassName("k-dropdown-wrap");
        gridpager[0]?.setAttribute('aria-label', getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PagerDropdownAriaLabel", UIControlsText.PagerDropdownAriaLabel));
        var gridpageroptions = document.querySelectorAll(".k-dropdown-wrap > span");
        gridpageroptions[0]?.setAttribute('role', 'option');
    })();

    //Fixing Accessibility issue for grid filterTextbox
    (function () {
        var filterTextbox = document.querySelectorAll(".k-filtercell .k-filtercell-wrapper > .k-textbox");
        for (let i = 0; i < filterTextbox.length; i++) {
            filterTextbox[i].setAttribute('aria-label', 'textbox');
        }
    })();

    //Fixing Accessibility issue for grid checkbox
    (function () {
        var gridCheckbox = document.getElementsByClassName("k-checkbox");
        for (let i = 0; i < gridCheckbox.length; i++) {
            gridCheckbox[i].setAttribute('aria-label', 'checkbox');
        }
    })();

    //fixing Accessibility issue when no rows selected in grid
    (function () {
        var gridRows = document.getElementsByClassName("k-grid-norecords");
        for (let i = 0; i < gridRows.length; i++) {
            gridRows[i].removeAttribute('aria-rowindex');
            gridRows[i].setAttribute('role', 'alert');
        }
    })();

    //fixing Accessibility issue when no rows selected in grid
    (function () {
        var gridRow = document.querySelectorAll('[role="row"]');
        for (let i = 0; i < gridRow.length; i++) {
            gridRow[i].removeAttribute('aria-rowindex');
        }
    })();

    // Fix for accessibility issue - No label text found for input elements
    (function () {
        var filesTxt = document.querySelectorAll('input[type="file"]');
        for (let i = 0; i < filesTxt.length; i++) {
            filesTxt[i].setAttribute('aria-label', 'files');
        }
    })();

    useToRemoveInvalidChildRole();
    //useToFixGridAriaRequiredChildren();

    const clearGridData = () => {
        props.kendoGridProps.data = []
        setGridData([]);
        if (props.onDeleteCallBack) {
            props.onDeleteCallBack({}, []);
        }
    };
    const SetGridWidth = () => {
        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(props.kendoGridProps.width)) {
            setGridWidth("auto");
        } else if (props.kendoGridProps.width === "0") {
            let headerElement = document.getElementsByClassName("headerStyle");
            let width = headerElement.length > 0 ? headerElement[0].clientWidth + "px" : "auto";
            setGridWidth(width);
        }
    }
    //method to download grid data in to excel file
    const _export = React.useRef<ExcelExport | null>(null);
    const excelExport = async () => {
        let apiReturnedData:any
        if (_export.current !== null) {
            if (props.kendoGridProps.gridName === DealType.BidGrid) {
                var gridColumns =  props.kendoGridProps.columns.filter((col) => col.isDynamic === true).map((c) => c.columnTitle)
                if(gridColumns != null && gridColumns != undefined && gridColumns.length > 0){
                    var excelData = props.excelExport?.(gridData, gridColumns);
                    if(excelData != null && excelData != undefined){
                        props.kendoGridProps.excelData = excelData;
                        _export.current.save(excelData);
                    }
                    else
                    {
                        _export.current.save();
                    }
                }
            } else {
                try {
                    const userSession = getUserSession();
                    if (userSession != null) {
                        let loggedInUserInfo = JSON.parse(userSession);
                        let roles = userRoles.split(",");
                        let sort: any;
                        let userMainRoleIsMSS = (userMainRole === UserRole.MSSeller) ? true : false;
                        const dashboardRequest: IDashboardDataRequest = {
                            userRoleDetails: loggedInUserInfo.userRoleDetails,
                            dealType: props.kendoGridProps.gridName === GridType.AllCaseView && !userMainRoleIsMSS ? `${DealType.CPStrategic},${DealType.DealRegistration},${DealType.SpecialPricing},${DealType.OTLE}`
                            : props.kendoGridProps.gridName === GridType.AllCaseView && userMainRoleIsMSS ? `${DealType.CPStrategic},${DealType.OTLE}`
                            : props.kendoGridProps.gridName === GridType.CPStrategicCaseView ? DealType.CPStrategic 
                            : props.kendoGridProps.gridName === GridType.ProductInformationView ? DealType.SpecialPricing 
                            : props.kendoGridProps.gridName === GridType.SPLocalEmpowerment? DealType.SpecialPricing
                            : props.kendoGridProps.gridName === GridType.OneTimeLocalEmpowerment? DealType.OTLE:props.kendoGridProps.gridName,
                            email: loggedInUserInfo.email,
                            userRoles: roles.join(","),
                            filter: JSON.stringify(filter),
                            pageNumber: 1,
                            recordsToFetch: 50000,
                            sort: sort,
                            isAllCasesDashboardRequest: props.kendoGridProps.gridName === GridType.AllCaseView ? true : false,
                            isLocalEmpowerment:props.kendoGridProps.gridName === GridType.SPLocalEmpowerment?true:false
                        };
                        setIsDownloading(true);
                        if (props.kendoGridProps.gridName === GridType.ProductInformationView) {
                            apiReturnedData = await Promise.resolve(nextGenService.getProductinformationDashboardData(dashboardRequest))
                        } else {
                            let pricingData = await Promise.resolve(nextGenService.getPricingProgramDashboardData(dashboardRequest));
                            apiReturnedData=pricingData.data.item1
                        }
                        setIsDownloading(false);
                    }
                } catch (error) {
                    setIsDownloading(false);
                }
                _export.current.save(apiReturnedData);
            }
        };
    };

    const CategoryFilterCell = (filterProps: any) => (
        <DropdownFilterCell
            {...filterProps}
            data={props.dropDownFilterContent}
            defaultItem={filterProps.defaultItem}
        />
    );

    //Generates the grid columns for the childcolumns of a single column
    const getChildColumns = (kendoGridChildColumns: IKendoGridColumnProps[]) => {
        if (kendoGridChildColumns === undefined) {
            return [];
        }
        const tempKendoGridColumnsProps = kendoGridChildColumns.map((col) => {
            return <GridColumn
                key={col.key} field={col.apiField} title={col.columnTitle} width={col.width}
                editable={col.isEditable} sortable={col.allowSorting} filterable={col.allowFilter} />
        });
        return tempKendoGridColumnsProps
    }

    //Toggle Grid Data (Expand All and Collapse All)
    const toggleExpand = () => {
        setToggleExpandCollapse(toggleText === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll)) ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CollapseAll", UIControlsText.CollapseAll) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll));
        let toggleData = gridData.map(eachRowItem => {
            eachRowItem.details = props.kendoGridProps.masterData?.filter((eachRecord: any) => eachRecord[props.kendoGridProps.groupByColumn] === eachRowItem[props.kendoGridProps.groupByColumn]);
            if (isNullOrEmpty(eachRowItem.expanded)) {
                eachRowItem.expanded = true;
            }
            else {
                eachRowItem.expanded = toggleText === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll) ? true : false;
            }
            return eachRowItem;
        });
        setGridData(toggleData);
    };

    const gridRenderCustomCell = (selectedRowData: any) => (
        props.onCustomCellRenderCallBack ? props.onCustomCellRenderCallBack(selectedRowData) : ""
    );
    const addNewPanel = () => {
        if (props.onAddNewPanelCallBack) {
            props.onAddNewPanelCallBack();
        }
    };
    const isColumnFilterActive = (field: string) => {
        return IsFilterActive(field, filter);
    };

    const [selectedKey, setSelectedKey] = React.useState<string | undefined>();

    const onRadioSelectionChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption, dataItem?: any) => {
        setSelectedKey(option?.key);
        const selected = { [`${option?.key.split("_")[0]}`]: true };
        setSelectedCheckState(selected);
        const actionMenuConfig = enableActionMenu(dataItem);
        setActionMenuConfig({ actionConfig: actionMenuConfig, selectedData: dataItem});
    };

    const RadioSelectionColumn = (menuprops?: any) => {
        const { dataItem } = menuprops;
        const navigationAttributes = useTableKeyboardNavigation(dataItem.id);
        let radioKey: string = ""
        if (props.kendoGridProps.gridName === ApplicationConstants.productInformationGrid) {
            radioKey = `${dataItem.opportunityDealId}_${dataItem.skuPartNumber}`
        } else {
            radioKey = `${dataItem.id}_${dataItem.name}`
        }
        const options: IChoiceGroupOption[] = [
            { key: radioKey, text: '', ariaLabel: 'pick one', styles: { root: { marginTop: 0 } } },
        ];
        return (<td
            style={menuprops.style} // this applies styles that lock the column at a specific position
            className={menuprops.className} // this adds classes needed for locked columns
            colSpan={menuprops.colSpan}
            role={"gridcell"}
            aria-colindex={menuprops.ariaColumnIndex}
            aria-selected={menuprops.isSelected}
            {...{ [GRID_COL_INDEX_ATTRIBUTE]: menuprops.columnIndex }}
            {...navigationAttributes}
        >
            <ChoiceGroup className="dashboardRadioButton" selectedKey={selectedKey} options={options} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => { onRadioSelectionChange(ev, option, dataItem) }} />
        </td>)
    };

    // CallBack Method to load the dashboardGridData onColumnChange
    const updateDashboardColumnsData = (selectedColumns?: IKendoGridColumnProps[]) => {
        const updateDashboardColumns =
            selectedColumns || [];
        setColumns(updateDashboardColumns)
    }

    //method to update grid data and count based on filter from context menu of grid column
    const filterChange = (e: GridFilterChangeEvent) => {
        if (e.filter) {
            if (e.filter.filters.length >= 1) {
                e.filter.filters = e.filter.filters.map((filter: any) => { return { logic: filter.logic, filters: filter.filters.map((filter1: any) => { return { field: filter1.field, operator: filter1.operator, value: Object.prototype.toString.call(filter1.value) === '[object Date]' ? new Date(new Date(filter1.value).getFullYear(),new Date(filter1.value).getMonth(), new Date(filter1.value).getDate()) : filter1.value.trim(), };}),}; });
                setFilter(e.filter)
                setGridCount(filterBy(props.kendoGridProps.data || [], e.filter).length);
                setGridData(filterBy(props.kendoGridProps.data || [], e.filter));
                setPage(initialPageState);
            }
            else {
                setFilter(e.filter)
                setGridCount(filterBy(gridData || [], e.filter).length);
                setGridData(filterBy(gridData || [], e.filter));
                setPage(initialPageState);
            }
        }
        else {
            setFilter(e.filter)
            setGridCount(props.kendoGridProps.totalRecords);
            setGridData(props.kendoGridProps.data);
        }
        const dataState = { sort: sort, skip: page.skip, take: page.take, filter: e.filter }
        props.onGetDataRequest?.(dataState,true)
    };



    //event to trigger on page change on pagination
    const pageChange = (event: GridPageChangeEvent) => {
        if (event.page.skip === 0) {
            let pageNumber = updateDefaultPageSizesInSession(props.kendoGridProps.gridName, event.page.take)
        }
        props.onPageChange?.(event);
        const dataState = { sort: sort, skip: event.page.skip, take: event.page.take, filter: filter }
        props.onGetDataRequest?.(dataState)
        setPage(event.page);
        updateCaseActionOnPageChange(event.page.skip, event.page.take);
    };

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
        if (event.sort) {
            if (event.sort.length >= 1) {
                setGridData(orderBy(gridData || [], event.sort));
            }
        }
        const dataState = { sort: event.sort, skip: page.skip, take: page.take, filter: filter }
        props.onGetDataRequest?.(dataState, true)
    }

    useEffect(() => {
        const menuConfig = { ...actionMenuConfig }
        const actionConfig: IActionButtonConfig = {
            showCPStrategicLetters: manufactureConfirmatonLetterVisible(props.kendoGridProps, getLoggedinUserRoleDetails()),
        }
        menuConfig.actionConfig = actionConfig
        setActionMenuConfig(menuConfig)
    }, [])

    const pagerSettings: GridPagerSettings = {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: props.kendoGridProps.pageSizes,
        previousNext: true,
    };

    const updateCaseActionOnPageChange = (skip: number, take: number) => {
        let selectedRowId = Object.keys(selectedCheckState)[0];
        let selectedData = gridData?.slice(skip, take + skip)?.find(x => x.id === selectedRowId);
        if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedData)) {
            const actionMenuConfig = enableActionMenu(selectedData);
            actionMenuConfig.showCPStrategicLetters = manufactureConfirmatonLetterVisible(props.kendoGridProps, getLoggedinUserRoleDetails());
            setActionMenuConfig({ actionConfig: actionMenuConfig, selectedData: selectedData });
        } else {
            const menuConfig = {} as IActionMenuProps;
            const actionConfig: IActionButtonConfig = {
                showCPStrategicLetters: manufactureConfirmatonLetterVisible(props.kendoGridProps, getLoggedinUserRoleDetails()),
            }
            menuConfig.actionConfig = actionConfig
            setActionMenuConfig(menuConfig)
        }
    }
    
    useEffect(() => {
        setFilterValue(props.kendoGridProps.filterValue)
    }, [props.kendoGridProps.filterValue])

    useEffect(() => {
        if (props.kendoGridProps.defaultFilter) {
            var filters: { field: any; operator: string; value: any; }[] = []
            props.kendoGridProps.defaultFilter.map((col: any) => {
                let statusValues = col.filterValues.split(",");
                statusValues.forEach((ele: any) => {
                    filters.push(
                        {
                            "field": col.columnName,
                            "operator": "eq",
                            "value": ele
                        },
                    )
                });
            });

            initialFilter = {
                logic: "and", filters: [
                    {
                        "logic": "or",
                        "filters": filters
                    }
                ]
            };
            
            setFilter(initialFilter);
            setGridCount(filterBy(props.kendoGridProps.data || [], initialFilter).length);
        }
    }, []);

    useEffect(() => {
        setGridCount(filterBy(props.kendoGridProps.data || [], filter).length);
    }, [props.kendoGridProps.data]);

    const SkuComponentHandler = (props: any) => {
        return <SkuComponent {...props} filterValue={filterValue} />
    }

    useEffect(() => {
        loadMessages(messages.en, ApplicationConstants.EnLocaleForGrid);
        loadMessages(messages.en_more_records, ApplicationConstants.EnLocaleForGridForMoreRecord);
    }, [])

    useEffect(() => {
        let count = isNullOrEmpty(props.kendoGridProps.totalRecords) ? props.kendoGridProps.data?.length : props.kendoGridProps.totalRecords;
        if (count > 0 && ((count % props.kendoGridProps.recordsToFetch) === 0)) {
            setlocaleMessageForMoreRecords(ApplicationConstants.EnLocaleForGridForMoreRecord);
        }
    }, [props.kendoGridProps.data, props.kendoGridProps.totalRecords, props.kendoGridProps.recordsToFetch])

    const CheckBoxFilterMenu = (gridPropsFilter: GridColumnMenuProps) => {
    const data = getValuesForCheckboxList(gridPropsFilter, props.kendoGridProps);
        return FilterMenuCheckboxFilter(gridPropsFilter, data);
    }

    useToFixGridAriaRequiredChildren('#mdppAccessibilityGridContainer');
    useToHandleOpenColumnMenuOnKeyboardEnter();

    return (
        <React.Fragment>
            <ExcelExport data={orderBy(excelData !== undefined && excelData.length > 0 ? excelData : gridData || [], sort)} fileName={props.kendoGridProps.gridName + " " + date} ref={_export}>
                {props.kendoGridProps.excelColumns !== undefined ? (
                    props.kendoGridProps.excelColumns.map((col) => {
                        return (
                            <ExcelExportColumn field={col.apiField} title={col.columnTitle} />
                        );
                    })
                ) :
                    defaultGridState.kendoGridProps.columns.map((col) => {
                        if (allowView(col)) {
                            return (
                                <ExcelExportColumn field={col.apiField} title={col.columnTitle} />
                            );
                        }
                    })
                }
            </ExcelExport>
            <LocalizationProvider language={localeMessageForMoreRecords}>
            <div id="mdppAccessibilityGridContainer">
                {(props.kendoGridProps.actionTypes?.showAddNewRowGridAction ||
                    props.kendoGridProps.actionTypes?.showClearAllGridAction ||
                    props.kendoGridProps.actionTypes?.showDownloadExcelGridAction ||
                    props.kendoGridProps.actionTypes?.showActionMenuBar ||
                    props.kendoGridProps.actionTypes?.showMultiSelectDropDownGridAction) &&
                    <GridToolbar>
                        {
                            props.kendoGridProps.actionTypes?.showActionMenuBar &&
                            <ActionMenu {...actionMenuConfig}/>
                        }

                        <Stack horizontal className="ms-auto" styles={{ root: { height: 28 } }}>
                            {
                                props.kendoGridProps.actionTypes?.showAddNewRowGridAction &&
                                <PrimaryButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddNew", UIControlsText.AddNew)} onClick={addNew} className="me-2" />
                            }
                            {props.kendoGridProps.actionTypes?.showDownloadExcelGridAction && props.kendoGridProps.data?.length > 0 &&
                                <CommandBarButton iconProps={ExportIcon} text={isDownloading ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Downloading", UIControlsText.Downloading) : props.kendoGridProps.gridName === DealType.BidGrid ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExportSkuTOExcel", UIControlsText.ExportSkuTOExcel) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExportToExcel", UIControlsText.ExportToExcel)} styles={ActionButtonStyles} onClick={excelExport} disabled={isDownloading} />
                            }
                            {props.kendoGridProps.actionTypes?.showClearAllGridAction && props.kendoGridProps.data?.length > 0 &&
                                <PrimaryButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ClearAll", UIControlsText.ClearAll)} onClick={clearGridData} className="me-2" />
                            }
                            {props.kendoGridProps.actionTypes?.showMultiSelectDropDownGridAction && props.kendoGridProps.data?.length > 0 &&
                                <MultiSelectDropDown dashBoardData={columns} updateDashboardGridColumnsData={updateDashboardColumnsData} gridName={props.kendoGridProps.gridName}></MultiSelectDropDown>
                            }
                        </Stack>

                    </GridToolbar>
                }
                <Grid
                    data={props.kendoGridProps.expandField !== "" ?
                        orderBy(filterBy(gridData || [], filter)?.slice(page.skip, page.take + page.skip) || [], sort) :
                        props.kendoGridProps.isServerSideGrid ?
                            orderBy(filterBy(gridData || [], filter).map(item => ({ ...item, [props.kendoGridProps.selectedField]: selectedCheckState[idGetter(item)] })) || [], sort) :
                            orderBy(filterBy(gridData || [], filter)?.map(item => ({ ...item, [props.kendoGridProps.selectedField]: selectedCheckState[idGetter(item)] })).slice(page.skip, page.take + page.skip) || [], sort)
                    }
                    skip={page.skip}
                    take={page.take}
                    dataItemKey={props.kendoGridProps.dataItemKey}
                    selectedField={props.kendoGridProps.selectedField}
                    selectable={{
                        enabled: false, drag: false, cell: false,
                        mode: props.kendoGridProps.selectionType === GridSelectionType.Mulitple ? 'multiple' : 'single'
                    }}
                    resizable={props.kendoGridProps.resizable}
                    sortable={props.kendoGridProps.sortable}
                    sort={sort}
                    pageable={pagerSettings}
                    filter={filter}
                    filterable={props.showFilterTextBox ? true : false}
                    onFilterChange={filterChange}
                    onItemChange={onItemChange}
                    onSelectionChange={props.kendoGridProps.editMode ? undefined : onSelectionChange}
                    onHeaderSelectionChange={props.kendoGridProps.selectionType === GridSelectionType.Mulitple ? onHeaderSelectionChange : undefined}
                    onPageChange={pageChange}
                    onSortChange={onSortChange}
                    total={gridCount}
                    className={props.kendoGridProps.className}
                    cellRender={customCellRender}
                    rowRender={props.kendoGridProps.actionTypes?.showGridContextMenuAction ? null : customRowRender}
                    editField={props.kendoGridProps.editField}
                    onExpandChange={expandChange}
                    expandField={props.kendoGridProps.expandField}
                    detail={props.kendoGridProps.customDetailComponent === 'sku' ? SkuComponentHandler : DetailComponent}
                    style={{ width: gridWidth }}
                    navigatable={true}
                >

                    
                    {props.kendoGridProps.actionTypes?.showGridContextMenuAction &&
                        <GridColumn cell={VerticalMenuCell} filterable={false} width="40px" />
                    }
                    {props.kendoGridProps.actionTypes?.showRadioSelectionAction &&
                        <GridColumn field={props.kendoGridProps.selectedField} headerCell={() => { return (<td><span></span></td>) }} cell={RadioSelectionColumn} filterable={false} sortable={false} locked={true} />
                    }
                    {props.kendoGridProps.actionTypes?.showSelectAllCheckboxGridAction &&
                        <GridColumn field={props.kendoGridProps.selectedField} filterable={false}
                            headerSelectionValue={
                                gridData?.findIndex((item: any) => !selectedCheckState[idGetter(item)]) === -1
                            } />
                    }
                    {columns.map((col) => {
                        if (allowView(col)) {
                            if (col.key != "errors")
                                return <GridColumn
                                    cell={CustomKendoGridCellConstants[col.type ? col.type : '']}
                                    key={col.key} field={col.apiField} title={col.columnTitle} width={col.width}
                                    editable={col.isEditable} sortable={col.allowSorting} filterable={col.allowFilter}
                                    editor={col.editorType} className={col.cssClass} footerCell={footerCellwithTotal}
                                    format={col.editorFormat} columnMenu={col.allowFilter && col.isDefaultFilterTobeApplied ? CheckBoxFilterMenu : col.allowFilter ? FilterMenu : undefined}
                                    children={getChildColumns(col.childColumns)} groupable={col.allowGroupable} headerClassName={isColumnFilterActive(col.apiField) ? ("active " + col.headerClassName) : col.headerClassName}
                                    filter={col.filterType} locked={col.locked}
                                />
                            else
                                return <GridColumn
                                    key={col.key} field={col.apiField} title={col.columnTitle} width={col.width}
                                    editable={col.isEditable} sortable={col.allowSorting} filterable={col.allowFilter}
                                    className={col.cssClass} cell={gridErrorCell} groupable={col.allowGroupable}
                                    children={getChildColumns(col.childColumns)}
                                />
                        }

                    })}
                    {props.kendoGridProps.additionalColumns?.map((col) => {
                        return <GridColumn field={col.apiField} key={col.key} title={col.columnTitle}
                            headerCell={MandatoryHeaderCell} filterable={col.allowFilter} width={col.width}
                        />
                    })}
                    {props.kendoGridProps.actionTypes?.showRowDeleteGridAction &&
                        <GridColumn cell={gridDeleteCell} title="Actions" filterable={false} />
                    }
                    {props.kendoGridProps.actionTypes?.showRowDownloadGridAction &&
                        <GridColumn cell={gridDownloadCell} title="Actions" filterable={false} />
                    }
                    {props.kendoGridProps.gridName === DealType.BidGrid ? (
                        <GridNoRecords>
                            No matching records are found for the selected criteria.
                        </GridNoRecords>
                    ):<GridNoRecords>
                    {props.kendoGridProps.customGridMessage}
                    </GridNoRecords>
                    
                    }
                </Grid>
            </div>
            </LocalizationProvider>
        </React.Fragment>
    )
}
export default DashboardGrid;